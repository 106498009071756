$window-inner-height: var(--window-inner-height);

.main-page {
    // min-height: 100vh;
    box-sizing: border-box;
    padding: 40px 0 24px;

    .container {
        display: flex;
        flex-direction: column;
        // border-bottom: 1px solid #606060;
    }

    .choose-foto-container {
        position: relative;
        flex-direction: column;
        align-items: flex-start;

        @media all and (max-width: 900px) {
            align-items: center;
           margin-bottom: 16px;
        }

        p {
            font-size: 40px;
            line-height: 1.6;
            text-align: center;
        }
    }

    @media all and (max-width: 900px) {
        .choose-foto-container p {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }
      
    @media all and (max-height: 960px) and (orientation: landscape) {
        .choose-foto-container p {
          font-size: 40px;
        }
    }
      
    @media all and (max-height: 800px) and (orientation: landscape) {
        .choose-foto-container p {
          font-size: 40px;
        }
    }
      
    @media all and (max-height: 780px) and (orientation: landscape) {
        .choose-foto-container p {
          font-size: 40px;
        }
    }
}

.logo {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.2;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    box-sizing: border-box;
}

//.safe-and-cool {
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//
//    a {
//      display: flex;
//      justify-content: center;
//      align-items: center;
//      width: 100%;
//      text-align: center;
//      padding: 20px 10px;
//      box-sizing: border-box;
//      font-size: 20px;
//      font-weight: 700;
//      color: black;
//      background-color: #01e777;
//      background-image: linear-gradient(to right, #01e777 43%, #01bbe4 100%);
//    }
//
//    svg {
//        width: 24px;
//        margin-left: 16px;
//    }
//
//    span {
//      text-decoration: underline;
//    }
//}

//@media all and (max-width: 900px) {
//    .safe-and-cool {
//        a {
//            font-size: 16px;
//            padding: 12px;
//        }
//    }
//}

.main-title {
    font-weight: 700;
    font-size: 70px;
    line-height: 1.2;
    text-align: left;
    max-width: 750px;
    text-shadow: 0 0 8px #000;
    margin-bottom: 32px;
}

.main-subtitle {
    font-size: 40px;
    line-height: 1.6;
    text-align: left;
    max-width: 590px;
    margin-bottom: 48px;
}

//.slide-item {
//    margin: 0 7px 14px;
//}

//.slide-item h2 {
//    font-size: 16px;
//    font-weight: 700;
//    font-style: oblique;
//    line-height: 1;
//    text-align: center;
//    color: #fff;
//    margin-bottom: 10px;
//}

//.slide-image-container {
//    max-width: 140px;
//    border-radius: 16px;
//    overflow: hidden;
//}

//.slide-image-container img {
//    width: 100%;
//    height: 100%;
//    object-fit: cover;
//}

//.slide-item-active {
//    position: relative;
//}

//.slide-item-active .slide-image-container {
//    box-shadow: 0 0 120px 0 #7ecd21;
//    border: 2px solid #7ecd21;
//    box-sizing: border-box;
//    max-width: 200px;
//}

.btn-upload-foto {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    max-width: 320px;
    width: 100%;
    height: 80px;
    border-radius: 100px;
    background-color: #01e777;
    display: block;
    flex-shrink: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 48px;

    &:active {
        opacity: .6;
    }
}

//.btn-another-foto {
//    font-size: 15px;
//    font-weight: 700;
//    color: #f2bc19;
//    display: flex;
//    align-items: center;
//    background-color: transparent;
//    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//    width: 100%;
//    height: 48px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    margin-bottom: 12px;
//    &:hover {
//        opacity: .6;
//    }
//}

.btn-change-mask {
    width: 32px;
    height: 32px;
    background: none;
    flex-shrink: 0;
    position: relative;
    z-index: 10;

    svg {
        width: 100%;
    }

    &.--animate {
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background-color: #01e777;
            animation: animate 3s ease 0s 2 forwards;
            z-index: -1;
        }

        @keyframes animate {
            0% {
                transform: translate(-50%, -50%) scale(1);
                opacity: 0;
            }
            49% {
                transform: translate(-50%, -50%) scale(1);
                opacity: 0;
            }
            50% {
                transform: translate(-50%, -50%) scale(1);
                opacity: 1;
            }
            100% {
                transform: translate(-50%, -50%) scale(2);
                opacity: 0;
            }
        }
    }
}

.mask-container {
    position: relative;

    .tooltip {
        font-size: 12px;
        letter-spacing: -0.24px;
        color: #000;
        position: absolute;
        top: -42px;
        right: 9px;
        height: 30px;
        background-color: rgba(1, 231, 119, 1);
        min-width: 130px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        padding: 0 8px;

        span {
            white-space: nowrap;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 14px;
            bottom: -6px;
            width: 0;
            height: 0;
            border-width: 6px 5px 0 5px;
            border-color: rgba(1, 231, 119, 1) transparent transparent transparent;
            border-style: solid;
        }
    }
}

.creative-container {
    display: flex;
    align-items: center;
    justify-content: space-between  ;
    max-width: 694px;
    height: 54px;
    width: 100%;
    margin: 0 auto 24px;
}

.rules-list {
    &-container {
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.6);
        max-width: 480px;
        margin-bottom: 120px;
    }

    &-title {
        font-weight: 700;
        margin-bottom: 16px;
    }

    &-item {
        position: relative;
        padding-left: 24px;
        margin-bottom: 16px;

        &:before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #858585;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 15px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        span {
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}

.main-info-container {
    display: flex;
    border-radius: 64px;
    background-color: #212121;
    padding: 40px 40px 40px 0;
    margin-bottom: 123px;

    .info-logo {
        width: 296px;
        height: auto;
        aspect-ratio: 1.11278195;
        position: relative;
        left: -32px;
        margin-right: 64px;
    }
}

.main-info-content {
    .info-image {
        width: auto;
        height: 64px;
        aspect-ratio: 3.25;
        margin-right: 24px;
    }

    p {
        font-size: 40px;
        line-height: 1.6;
    }
}

.main-info-content-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    p {
        font-weight: 700;
        font-size: 24px;
        line-height: 1.67;
    }
}

.main-video-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 645px;
    // z-index: -5;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        padding-top: 123%;
    }

    @media all and (max-width: 1300px) {
        width: 510px;
    }

    @media all and (max-width: 1000px) {
        width: 420px;
    }

    .main-video {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -5;
    }
}

.main-arrow-icon {
    width: 416px;
    position: absolute;
    left: 320px;
    bottom: -60px;
    display: none;
}

//.create-page h2 {
//    font-weight: 700;
//    color: #333;
//    line-height: 1.38;
//    text-align: center;
//    margin-bottom: 20px;
//}

//.create-page .create-steps {
//    line-height: 1.38;
//    text-align: center;
//    color: #999;
//    margin-bottom: 8px;
//}

.creatives {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 30px;
}

.creative-holder {
    position: relative;
    width: 100%;
    flex-grow: 1;
    max-width: 694px;
    background-color: #212121;
    margin: 0 auto 8px;

    p {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
    }

    &:after {
        content: '';
        display: block;
        // padding-top: 100%;
    }
}

.creative {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    // height: fit-content;
    // width: fit-content;
    // max-width: 100%;
    // max-height: 100%;
}

//.creative .num-check-foto {
//    font-size: 10px;
//    font-weight: 700;
//    color: #fff;
//    position: absolute;
//    top: 8px;
//    right: 8px;
//    width: 22px;
//    height: 22px;
//    border-radius: 50%;
//    background-color: #05b0ff;
//    display: none;
//    align-items: center;
//    justify-content: center;
//}

.creative .holder {
    position: relative;
    margin-bottom: 8px;
    padding-top: 100%;
    background-color: #eeeeee;
}

//.creative.active .num-check-foto {
//    display: flex;
//}

.creative img {
    height: 100%;
    width: 100%;
    border-radius: 2px;
    margin: 0 auto;
    display: block;
    object-fit: contain;
    pointer-events: none;
}

.creative.active img {
    // box-shadow: 0 0 40px 0 #c77dff;
    border: solid 3px #05b0ff;
    box-sizing: border-box;
}

.creative p {
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: #333;
    text-transform: capitalize;
}

//.create-page .btn-upload-foto {
//    position: fixed;
//    bottom: 15px;
//    width: 100%;
//    left: 50%;
//    transform: translateX(-50%);
//}

//.create-page .btn-upload-foto.disabled {
//    background-color: #3b3c3e;
//}

//.collage-page {
//    display: flex;
//    flex-direction: column;
//    height: 90%;
//    // overflow: hidden;
//    padding-bottom: 0;
//    .btn-upload-foto {
//        max-width: 200px;
//    }
//
//    .footer-links {
//        display: none;
//    }
//}

.btns-container {
    position: relative;

    &-store {
        display: flex;
        justify-content: center;
        margin-bottom: 120px;
    }
}

.btn-store {
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 2.4px;
    color: #000;
    width: 320px;
    height: 80px;
    border-radius: 40px;
    background-color: #fff;
    text-transform: uppercase;
    margin: 0 24px;

    &:hover {
        opacity: .6;
    }
}

//.btn-refresh-foto {
//    font-size: 10px;
//    color: #fff;
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    position: absolute;
//    bottom: 0;
//    left: 50%;
//    transform: translate(110px, 5px);
//    background-color: transparent;
//    -webkit-tap-highlight-color: rgba(0,0,0,0);
//    padding: 5px;
//    svg {
//        width: 24px;
//    }
//}

//.collage-page .creative-holder {
//    position: relative;
//    max-width: 450px;
//    width: 100%;
//    margin: 0 auto 24px;
//
//    .creative-holder-placeholder {
//        padding-top: 100%;
//    }
//
//    .wait-video, video, img {
//        position: absolute;
//        top: 0;
//        left: 0;
//        display: block;
//        width: 100%;
//        height: 100%;
//        z-index: 20;
//    }
//
//    .imageview-host {
//        z-index: 20;
//    }
//
//    .wait-video {
//        z-index: 5;
//    }
//}

//.collage-page img,
//.collage-page video {
//    width: 100%;
//    max-width: 450px;
//    // max-height: 450px;
//    height: 100%;
//    display: block;
//    margin: 0 auto;
//}

//.collage-page img {
//    object-fit: contain;
//    width: auto;
//    max-width: 100%;
//    max-height: 100%;
//    border-radius: 24px;
//    height: auto;
//}
//.collage-page video {
//    object-fit: contain;
//}

//.collage-page .container {
//    display: flex;
//    flex-direction: column;
//    align-items: flex-start;
//}

//.collage-page-content {
//    // flex-grow: 1;
//    display: flex;
//    flex-direction: column;
//    justify-content: center;
//}
//.collage-page-text {
//    margin-bottom: 27px;
//}

/*loader*/
.loader {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    position: relative;

    .loader-tip {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        color: #01e777;
        margin-bottom: 16px;
    }
}

//.collage-page-text {
//    margin-bottom: 27px;
//}

.loader-text-container {
    position: absolute;
    top: calc(40% + 220px);
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 80%;
}

.loader p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #fff;
}

.spinner-container h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.avatar-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    animation-name: fadein;
    animation-duration: 320ms;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    pointer-events: none;
}

.spinner-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    h3 {
        font-size: 28px;
        line-height: 1;
        color: #95e09e;
        width: 140px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 2px rgba(255, 255, 255, 0.25);
        border-radius: 50%;
    }

    svg {
        width: 120px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.triple-spinner {
    display: block;
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    border: solid 1px rgba(255, 255, 255, 0.15);
}

.triple-spinner2 {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: solid 1px rgba(255, 255, 255, 0.15);
}

.triple-spinner::before,
.triple-spinner2::before  {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 1px solid transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.triple-spinner::before {
    border: 1px solid #01e777;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation: spin-reverse 1.5s linear infinite;
}

.triple-spinner2::before {
    border: 1px solid #01e777;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

/*modal*/
.-show-popup {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(30px);
    background-color: rgba(1, 231, 119, 0.15);
    z-index: 80;
}

.modal {
    max-width: 640px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 24px;
    box-shadow: 0 8px 60px 0 rgba(0, 0, 0, 0.6);
    background-color: #000;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 50px;

    svg {
        position: absolute;
        top: 0;
        right: -2px;
        left: -2px;
        width: calc(100% + 4px);
        z-index: -5;
    }
    img {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-top: -40px;
    }
    p {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #fff;
        margin-bottom: 48px;

        span {
            font-weight: bold;
            color: #01e777;
        }
    }
    .btn-upload-foto {
        color: #fff;
        max-width: 100%;
        width: 100%;
    }
    h3 {
        font-size: 40px;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #fff;
        margin-bottom: 32px;
    }

    &_rate {
        padding: 76px 50px;

        p {
            font-size: 24px;
            margin-bottom: 64px;
        }

        h3 {
            span {
                color: #01e777;
            }
        }

        button {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
            letter-spacing: 2px;
            text-transform: uppercase;
            cursor: pointer;
            background: none;

            &:hover {
                opacity: .8;
            }
        }

        .rate-button {
            color: #000;
            width: 188px;
            height: 64px;
            border-radius: 40px;
            background-color: #01e777;
            margin-bottom: 20px;
        }

        .skip-button {
            color: #01e777;
            padding: 4px;
        }
    }

    @media all and (max-width: 780px) {
        padding: 24px;

        h3 {
            font-size: 24px;
            line-height: 1.5;
            margin-bottom: 16px;
        }

        p {
            font-size: 16px;
            margin-bottom: 24px;
        }

        &_rate {
            p {
                margin-bottom: 40px;
            }

            button {
                font-size: 16px;
                letter-spacing: 1.6px;
            }

            .rate-button {
                max-width: 240px;
                width: 100%;
                height: 48px;
                border-radius: 24px;
            }
        }
    }
}


//.btn-share {
//    width: 48px;
//    height: 48px;
//    border-radius: 50%;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    margin-right: 12px;
//    svg {
//        width: 25px;
//        height: 26px;
//        position: static;
//        z-index: 10;
//    }
//}
//.btn-share-snap {
//    background-color: #ffdd0d;
//}
//.btn-share-fb {
//    background-color: #1976d2;
//}
//.btn-share-insta {
//    background-image: linear-gradient(45deg,#fd5 7%,#ff543e 50%,#c837ab 93%);
//}

//.btn-close {
//    background-color: #95e09e;
//    color: #1a3b3b;
//    text-transform: uppercase;
//    font-weight: 700;
//    max-width: 240px;
//    width: 90%;
//    height: 48px;
//    border-radius: 100px;
//    flex-shrink: 0;
//    width: 100px;
//}

/*error-page*/
.error-page {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 94vh;
    }

    img {
        height: 200px;
        margin-bottom: 48px;
    }
    
    h3 {
        font-weight: 700;
        margin-bottom: 16px;
    }

    .btn-upload-foto {
        color: #01e777;
        background-color: transparent;
        border: 1px solid #01e777;
        max-width: inherit;
        width: auto;
        position: relative;
        overflow: hidden;
        padding: 0 34px;
        margin: 48px auto 0;

        .effects {
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            background-color: #01e777;
            color: #000000;
            border-radius: 100px;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            align-items: center;
            
            span {
                padding-left: 34px;
            }
        }
    }
}

.error-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.38;
    margin-bottom: 24px;

    p {
        text-align: center;
        line-height: 1.5;
    }
}

.btn-back {
    width: 40px;
    border: none;
    background: none;
    position: absolute;
    top: 44px;
    left: 80px;
    svg {
        width: 100%;
    }
}

//.collage-page h2 {
//    font-size: 16px;
//    font-weight: 700;
//    line-height: 1.38;
//    text-align: center;
//    color: #fff;
//    width: 100%;
//    margin-bottom: 16px;
//    text-transform: uppercase;
//}

//.share-container {
//    display: flex;
//    align-items: center;
//    overflow-x: auto;
//    // position: absolute;
//    // bottom: 0;
//    // left: 0;
//    // right: 0;
//    position: relative;
//    // left: -30px;
//    // width: calc(100% + 60px);
//    padding-top: 12px;
//    padding-bottom: 12px;
//    // border-top: 1px solid #d2d2d2;
//    // border-bottom: 1px solid #d2d2d2;
//    // margin-top: 10px;
//}

//.share-container button {
//    width: 40px;
//    height: 40px;
//    border: none;
//    background: no-repeat;
//    flex-shrink: 0;
//    margin-left: 24px;
//
//    &:last-child {
//        width: 1px;
//    }
//}

//.share-container svg {
//    width: 100%;
//}

//.steps-progress {
//    position: fixed;
//    height: 4px;
//    width: 100%;
//    background-color: #e7e7e7;
//    top: 0;
//    left: 0;
//    z-index: 50;
//
//    span {
//        transition: 500ms;
//        background-color: #feca28;
//        display: block;
//        height: 100%;
//    }
//}

//.tabs-container {
//    display: flex;
//    justify-content: center;
//    margin: 0 auto 14px;
//    width: 100%;
//
//    button {
//        font-size: 16x;
//        font-weight: 700;
//        line-height: 1;
//        letter-spacing: 2px;
//        text-align: center;
//        border-radius: 35px;
//        border-bottom: 3px solid transparent;
//        color: #fff;
//        flex-shrink: 0;
//        height: 48px;
//        max-width: 185px;
//        width: 45%;
//        background: none;
//        box-sizing: border-box;
//        margin: 0 5px;
//
//        svg, img {
//            width: 64%;
//        }
//    }
//
//    .active-tab-border {
//        opacity: 0;
//        width: 100%;
//        position: absolute;
//        bottom: 0;
//        left: 0;
//    }
//
//    .active-tab {
//        .active-tab-border {
//            opacity: 1;
//        }
//    }
//}

//.wait-video {
//    overflow: hidden;
//    max-width: 450px;
//    margin: 0 auto;
//
//    img {
//        filter: blur(7px);
//    }
//}

//.loader-video {
//    display: flex;
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%,-50%);
//    z-index: 10;
//}
//
//.loader-video .item-loader {
//    width: 9px;
//    height: 9px;
//    border-radius: 50%;
//    animation: item-loader .9s linear infinite;
//    margin: 0 6px;
//}
//
//.loader-video .first-item-loader {
//    background-color: #1db8ff;
//}
//
//.loader-video .second-item-loader {
//    background-color: #ffd300;
//    animation: item-loader .9s linear infinite;
//    animation-delay: .3s;
//}
//
//.loader-video .third-item-loader {
//    background-color: #fc4700;
//    animation: item-loader .9s linear infinite;
//    animation-delay: .6s;
//}


//@keyframes item-loader {
//    0% {
//        transform: scale(1, 1);
//    }
//
//    50% {
//        transform: scale(1.8, 1.8);
//    }
//}


//.collage-container {
//    flex-grow: 1;
//    display: flex;
//    flex-direction: column;
//    margin-bottom: 10px;
//    .container {
//        position: relative;
//        display: block;
//        height: 100%;
//        width: 100%;
//        flex-grow: 1;
//        padding: 0;
//    }
//}

//.collage-page {
//    padding-top: 32px;
//}

//.collage {
//    height: 100%;
//    width: 100%;
//    flex-grow: 1;
//    flex-shrink: 1;
//    flex-basis: 0;
//    min-height: 0;
//    background-size: contain;
//    background-repeat: no-repeat;
//    background-position: 50%;
//    max-width: 90%;
//    display: flex;
//    align-items: center;
//    margin: 16px auto;
//}

//.tab-content {
//    display: flex;
//    flex-direction: column;
//    flex-grow: 1;
//}

//.templates-container {
//    flex-shrink: 0;
//    max-width: 640px;
//    margin: 0 auto;
//    padding-top: 2px;
//    padding-bottom: 2px;
//
//    .container {
//        display: flex;
//        align-items: center;
//        flex-direction: row;
//        overflow: auto;
//        width: 100%;
//        max-width: 100%;
//        padding-top: 2px;
//        padding-left: 2px;
//        padding-right: 2px;
//        padding-bottom: 10px;
//
//        &::-webkit-scrollbar-track {
//            background-color: rgba(255, 255, 255, 0.15);
//            height: 10px;
//        }
//
//        &::-webkit-scrollbar {
//            padding: 10px 0;
//            height: 2px;
//            background-color: rgba(255, 255, 255, 0.15);
//        }
//
//        &::-webkit-scrollbar-thumb {
//            background-color: #fff;
//        }
//    }
//
//    button {
//        flex-shrink: 0;
//        overflow: auto;
//        background-size: cover;
//        display: flex;
//        align-items: flex-end;
//        justify-content: center;
//        font-size: 8px;
//        text-align: center;
//        color: #fff;
//        position: relative;
//        padding: 0 6px 4px;
//
//        span {
//            position: relative;
//            z-index: 10;
//            position: absolute;
//            bottom: 4px;
//            width: 86%;
//        }
//
//        &.named {
//            &:after {
//                content: "";
//                display: block;
//                width: 100%;
//                height: 25px;
//                background-image: linear-gradient(
//                                180deg,transparent,#000);
//                position: absolute;
//                bottom: 0;
//                left: 0;
//            }
//        }
//    }
//}

//.btn-choice-template {
//    // width: 96px;
//    // height: 96px;
//    margin-left: 8px;
//    opacity: 0.75;
//    width: 68px;
//    height: 68px;
//    border-radius: 14px;
//    // border-radius: 24px;
//
//    &.active {
//        // border: 3px solid #fff;
//        // opacity: 1;
//        box-shadow: 0 0 0 3px #fff;
//    }
//}

//.halloween-body-background img {
//    display: block;
//    width: 80%;
//    height: auto;
//    margin: auto;
//}

.root > div:not(.loader-container) {
    display: flex;
    flex-direction: column;
}

/*loader*/
//.loader-container {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    position: absolute;
//    top: 0;
//    width: 100%;
//    height: 100%;
//    flex-direction: column;
//    p {
//        color: #fff;
//        margin-bottom: 14px;
//    }
//}

//.loader-android {
//    width: 200px;
//    height: 2px;
//    background-color: rgba(5, 176, 255, 0.2);
//    position: relative;
//    overflow: hidden;
//    span {
//        position: absolute;
//        top: 0;
//        height: 100%;
//        width: 70px;
//        background-color: #05b0ff;
//        animation: line-loader .9s linear infinite;
//    }
//    @keyframes line-loader {
//        0% {
//            left: -70px;
//        }
//
//        100% {
//            left: calc(100% + 70px);
//        }
//    }
//}

//.loader-circle {
//    display: flex;
//    margin-bottom: 24px;
//
//    &-container {
//        display: flex;
//        flex-direction: column;
//        align-items: center;
//    }
//
//    .item-loader {
//        width: 9px;
//        height: 9px;
//        border-radius: 50%;
//        animation: item-loader .9s linear infinite;
//        background-color: #fff;
//        opacity: 1;
//        margin: 0 6px;
//    }
//
//    .second-item-loader {
//        animation: item-loader .9s linear infinite;
//        animation-delay: .3s;
//    }
//
//    .third-item-loader {
//        animation: item-loader .9s linear infinite;
//        animation-delay: .6s;
//    }
//}
  
  
//@keyframes item-loader {
//    0% {
//          transform: scale(1, 1);
//          opacity: 0;
//    }
//    80% {
//          transform: scale(1.8, 1.8);
//          opacity: 1;
//    }
//}

//.btn-done {
//    font-size: 16px;
//    font-weight: 700;
//    text-transform: uppercase;
//    color: #1a3b3b;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    width: 100%;
//    max-width: 180px;
//    height: 48px;
//    border-radius: 40px;
//    background-color: #95e09e;
//    margin: 0 auto;
//}

//.footer-btns {
//    position: relative;
//    max-width: 510px;
//    width: 100%;
//    margin: 30px auto 0;
//
//    &.invisible {
//        visibility: hidden;
//        pointer-events: none;
//    }
//
//}

//.step {
//    font-weight: 600;
//    font-size: 16px;
//    font-style: italic;
//    color: #305f3d;
//    line-height: 1;
//    letter-spacing: 0.5px;
//    text-align: center;
//    width: 100%;
//    margin-bottom: 8px;
//}

//.try-photo {
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    font-size: 8px;
//    font-weight: 500;
//    text-transform: uppercase;
//    color: #95e09e;
//    background: border-box;
//    width: 58px;
//    position: absolute;
//    right: 5%;
//    top: 0;
//
//    &-container {
//        width: 48px;
//        height: 48px;
//        border-radius: 50%;
//        border: solid 3px #95e09e;
//        box-sizing: border-box;
//        display: flex;
//        margin-bottom: 5px;
//    }
//
//    img {
//        width: 20px;
//        border-radius: 0;
//    }
//
//}


.error-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    box-sizing: border-box;

    p {
        line-height: 1.5;
    }

    button {
        height: 48px;
        border-radius: 100px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        letter-spacing: 0.8px;
        text-align: center;
        color: #01e777;
        text-transform: uppercase;
        border: solid 1px #01e777;
        background-color: transparent;
        padding: 0 54px;
        margin-top: 40px;

        @media all and (max-width: 780px) {
            font-size: 13px;
            height: 40px;
            letter-spacing: 1.3px;
        }
    }
}

.tab-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    flex-shrink: 0;
    overflow: auto;
    width: 100%;
    max-width: 730px;
    box-sizing: border-box;
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 10px;
    margin: 0 auto 12px;

    &::-webkit-scrollbar-track {
        background-color: transparent;
        height: 0;
        border-radius: 8px;
    }

    &::-webkit-scrollbar {
        padding: 10px 0;
        height: 0;
        border-radius: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 8px;
    }
}

.btn-choice-tab {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-shrink: 0;
    font-size: 8px;
    text-align: center;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.6);
    background-size: cover; 
    padding: 0 6px 4px;
    margin-left: 8px;

    &.active {
        border: 4px solid #01e777;
    }

    &.waiting {
        .timer-loader {
            z-index: 10;
        }
    }

    &.error {
        opacity: .35;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        background-color: #212121;
        .error-icon {
            display: block;
            width: 24px;
            height: 24px;
            fill: #fff;
            opacity: .3;
        }
    }

    &.active.waiting {
        border: 0;
        box-shadow: inset 0 0 0 4px #01e777;

        .timer-loader {
            box-sizing: border-box;
        }
    }

    &:first-child {
        margin-left: 6px;
    }

    &:last-child {
        margin-right: 6px;
    }

    .error-icon {
        display: none;
    }

    &.new {
        position: relative;
        overflow: hidden;

        span {
            font-size: 8px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            color: #000;
            position: absolute;
            top: 0;
            right: 0;
            width: 32px;
            height: 14px;
            padding: 3px 5px;
            border-radius: 0 0 0 8px;
            box-sizing: border-box;
            background-color: #ffcf05;
        }
    }
}

.btn-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #fff;
    letter-spacing: 0.8px;
    width: 48%;
    height: 48px;
    border-radius: 56px;
    border: solid 1px #fff;
    background: none;
    text-transform: uppercase;
}
.creative-download {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 188px;
    height: 64px;
    background-color: #01e777;
    border-radius: 56px;
}

.btns-container-creative {
    .creative-download {
        font-size: 16px;
        width: 48%;
        height: 48px;
    }
}

.result-page {
    // height: 94vh;
    height: $window-inner-height;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow-x: hidden;
    padding-top: 64px;
    padding-bottom: 24px;

    h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 24px;
    }

    .creative-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        box-sizing: border-box;
        padding: 0 10px;
    }
}

.ui--creative-tabs-v2 {
    .result-page {
        .creative-holder {
            padding: 24px 10px 0;
        } 
    }

    //@media (max-height: 920px) and (orientation: landscape) {
    //    .result-page.countdowntimer .creative-block,
    //    .result-page.countdowntimer-hide .creative-block {
    //        max-height: 48vh;
    //        margin-bottom: 64px;
    //    }
    //}

    //@media (max-height: 750px) and (orientation: landscape) {
    //    .result-page.countdowntimer .creative-block,
    //    .result-page.countdowntimer-hide .creative-block {
    //        max-height: 38vh;
    //    }
    //}

    //@media (max-height: 620px) and (orientation: landscape) {
    //    .result-page.countdowntimer .creative-block,
    //    .result-page.countdowntimer-hide .creative-block {
    //        max-height: 30vh;
    //    }
    //}

    //@media all and (max-height: 780px) and (orientation: portrait) {
    //    .countdowntimer {
    //        .btn-change-mask {
    //            top: 40px;
    //        }
    //
    //        .mask-container .tooltip {
    //            top: -10px;
    //
    //            @media (max-width: 560px) {
    //                top: 42px;
    //            }
    //        }
    //    }
    //}

    //@media all and (max-height: 720px) and (orientation: portrait) {
    //    .result-page.countdowntimer-hide .creative-block,
    //    .result-page.countdowntimer-hide .creative-block {
    //        max-height: 48vh;
    //    }
    //}

    //@media all and (max-height: 700px) and (orientation: portrait) {
    //    .result-page.countdowntimer .creative-block,
    //    .result-page.countdowntimer-hide .creative-block {
    //        max-height: 46vh;
    //    }
    //}

    //@media all and (max-height: 600px) and (orientation: portrait) {
    //    .result-page.countdowntimer .creative-block,
    //    .result-page.countdowntimer-hide .creative-block {
    //        max-height: 40vh;
    //    }
    //
    //    .result-page.countdowntimer-hide .creative-block,
    //    .result-page.countdowntimer-hide .creative-block {
    //        max-height: 36vh;
    //    }
    //}
}

//.countdowntimer {
//    &.result-page {
//        padding-top: 40px;
//        justify-content: flex-start;
//    }
//
//    .clock-container {
//        display: flex;
//        flex-direction: column;
//        align-items: center;
//        margin-bottom: 24px;
//    }
//
//    .clock-title {
//        font-size: 24px;
//        line-height: 1;
//        margin-bottom: 8px;
//    }
//
//    .countdown-timer__unit {
//        margin-right: 8px;
//
//        &:first-child {
//            margin-right: 8px;
//        }
//
//        &:last-child {
//            margin-right: 0;
//        }
//    }
//
//    .mask-container .tooltip {
//        top: 12px;
//        right: 58px;
//
//        &:after {
//            right: -6px;
//            bottom: 9px;
//            border-width: 5px 0px 5px 6px;
//            border-color: transparent transparent transparent rgba(1, 231, 119, 0.8);
//        }
//    }
//
//    .countdown-timer__card {
//        font-size: 40px;
//        height: 48px;
//        width: 48px;
//    }
//
//    .countdown-timer__unit_label {
//        font-size: 12px;
//    }
//
//    .tab-container {
//        margin-bottom: 16px;
//    }
//
//    @media (max-width: 460px) {
//        &.result-page {
//            padding-top: 30px;
//        }
//
//        .countdown-timer__card {
//            font-size: 24px;
//            height: 32px;
//            width: 32px;
//        }
//
//        .countdown-timer__unit_label {
//            font-size: 8px;
//            margin-top: 2px;
//        }
//
//        .clock-container {
//            flex-direction: row;
//            justify-content: center;
//            align-items: center;
//            padding: 0 16px;
//            margin-bottom: 16px;
//        }
//
//        .clock-title {
//            font-size: 8px;
//            font-weight: bold;
//            text-transform: uppercase;
//            line-height: 1.13;
//            width: min-content;
//            margin-bottom: 10px;
//            margin-right: 12px;
//        }
//
//        .tab-container {
//            position: relative;
//            z-index: 20;
//        }
//    }
//
//    @media (max-width: 320px) {
//        .countdown-timer__card {
//            font-size: 20px;
//            height: 28px;
//            width: 28px;
//        }
//    }
//}

//.choose-foto-container {
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    margin-bottom: 46px;
//
//    p {
//        font-size: 24px;
//        line-height: 1;
//        margin-bottom: 16px;
//    }
//
//    button {
//        font-size: 13px;
//        font-weight: bold;
//        letter-spacing: 1.3px;
//        text-transform: uppercase;
//        color: #fff;
//        height: 36px;
//        flex-grow: 0;
//        padding: 0 24px;
//        border-radius: 24px;
//        border: solid 1px #fff;
//        background: none;
//        flex-shrink: 0;
//    }
//
//    @media (max-width: 460px) {
//        flex-direction: row;
//        column-gap: 16px;
//        padding: 0 16px;
//        margin-bottom: 16px;
//
//        p {
//            font-size: 16px;
//            line-height: 1.25;
//            margin-bottom: 0;
//        }
//    }
//}

//.countdowntimer-hide {
//    &.result-page {
//        padding-top: 40px;
//        justify-content: flex-start;
//    }
//
//    .choose-foto-container {
//        margin-bottom: 48px;
//    }
//
//    .tab-container {
//        margin-bottom: 16px;
//    }
//
//    @media all and (max-width: 460px) {
//        &.result-page {
//            padding-top: 30px;
//        }
//
//        .choose-foto-container {
//            margin-bottom: 18px;
//        }
//    }
//
//    @media all and (max-width: 340px) {
//        .choose-foto-container button {
//            font-size: 12px;
//            letter-spacing: 1px;
//            height: 34px;
//            padding: 0 12px;
//        }
//     }
//
//    // @media (max-height: 1000px) and (orientation: landscape) {
//    //     &.result-page .creative-block {
//    //         max-height: 44vh;
//    //         margin-bottom: 64px;
//    //     }
//    // }
//
//    // @media (max-height: 780px) and (orientation: landscape) {
//    //     &.result-page .creative-block {
//    //         max-height: 36vh;
//    //     }
//    // }
//}

//.btn-get {
//    font-size: 20px;
//    font-weight: bold;
//    letter-spacing: 2px;
//    text-transform: uppercase;
//    height: 64px;
//    padding: 0 56px;
//    border-radius: 64px;
//    background-color: #01e777;
//}

.editor-page {
    display: flex;
    flex-direction: column;
    // height: 96vh;
    height: $window-inner-height;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 40px;

    h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 16px;
    }

    p {
        opacity: 0.6;
        line-height: 1.5;
        text-align: center;
        color: #fff;
        margin-bottom: 24px;
    }
}

//.creative-refresh {
//    border: none;
//    background: none;
//    display: flex;
//    align-items: center;
//
//    span {
//        font-weight: 700;
//        font-size: 12px;
//        line-height: 1.33;
//        letter-spacing: 0.6px;
//        color: #fff;
//        text-transform: uppercase;
//        margin-right: 24px;
//    }
//
//    img {
//        position: relative;
//        width: 32px;
//        height: 32px;
//        // border: solid 2px #000;
//        border-radius: 50%;
//        margin-left: -15px;
//
//        &:nth-child(1) {
//            z-index: 8;
//        }
//        &:nth-child(2) {
//            z-index: 7;
//        }
//        &:nth-child(3) {
//            z-index: 6;
//        }
//        &:nth-child(4) {
//            z-index: 5;
//        }
//        &:nth-child(5) {
//            z-index: 4;
//        }
//        &:nth-child(6) {
//            z-index: 3;
//        }
//        &:nth-child(7) {
//            z-index: 2;
//        }
//        &:nth-child(8) {
//            z-index: 1;
//        }
//    }
//}

.main-video-poster {
    width: 100%;
    height: auto;
    aspect-ratio: 0.824742268;

    position: absolute;
    z-index: -2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    object-fit: cover;

    &.hidden {
        transition: opacity 1s;
        opacity: 0;
    }
}

.ui--creative-tabs-v2 {
    .creative-container {
        position: relative;
        z-index: 50;
        margin-top: -10px;
        padding: 0;
    }

    .creative-tabs-container {
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;

        @media (max-height: 1050px) and (orientation: landscape) {
            max-width: 514px;
        }

        @media (max-width: 720px) {
            max-width: calc(100% + 24px);

        }
    }

    .creative-tabs {
        flex-direction: row;
        width: 100%;
        max-width: max-content;
        column-gap: 12px;
        padding-top: 20px;
        padding-bottom: 18px;
        padding-right: 16px;
        padding-left: 12px;
        margin-right: 0;
        box-sizing: border-box;

        button {
            width: 60px;
            height: 60px;
            margin-left: 0;
            margin-right: 0;

            &.active {
                height: 66px;
                width: 66px;
                box-shadow: 0 0 24px 0 #01e777;
            }
        }

        .timer-loader {
            width: 68px;
            height: 68px;
        }

        @media (max-width: 720px) {
            margin: 0;
            padding-left: 12px;
            padding-right: 12px;
            box-sizing: border-box;

            button {
                width: 48px;
                height: 48px;

                &.active {
                    width: 54px;
                    height: 54px;
                }
            }

            .timer-loader {
                width: 56px;
                height: 56px;
            }
        }
    }

    .result-page .creative-holder {
        padding: 0;
        margin-bottom: 0;
    }

    .mask-container {
        position: static;
    }

    .btn-change-mask {
        position: absolute;
        top: 9px;
        right: 9px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: rgba(1, 231, 119, 1);
        padding: 0;

        svg {
            width: 24px;
            margin-top: 4px;
        }
    }
}

.ui--creative-tabs-v2 .creative-block {
    display: flex;
    // max-height: 56vh;
    flex-grow: 1;
    height: 100%;
    position: relative;
    margin-bottom: 80px;

    @media (max-width: 720px) {
        margin-bottom: 50px;
    }

    .loader-roller-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .creative-container {
        position: absolute;
        bottom: -36px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
        padding: 0;
    }

    .creative {
        position: static;
        transform: none;
        img {
            // height: 100%;
            // max-height: 56vh;
            // width: 100%;
            border-radius: 2px;
            margin: 0 auto;
            display: block;
            object-fit: contain;
            pointer-events: none;
            position: absolute;
            z-index: 5;
        }

        .watermark-image {
           z-index: 10; 
        }
    }
}

.app-lang--ko,
.app-lang--ja {
    .mask_editor_screen .toolbox button {
        span {
            width: 250%;
        }
    }

    .mask_editor_screen .toolbox .tool_undo {
        margin-right: 48px;
    }
}

//.full-size-promotion-container {
//    min-height: 100vh;
//    height: 100%;
//    box-sizing: border-box;
//
//    img {
//        width: 100%;
//        height: 100%;
//        margin: 0 auto;
//        display: flex;
//        object-fit: contain;
//        max-width: 430px;
//        max-height: 62vh;
//    }
//
//    h2 {
//        font-size: 16px;
//        line-height: 1.25;
//        text-align: center;
//        color: #fff;
//        margin-bottom: 8px;
//
//        span {
//            font-weight: bold;
//        }
//    }
//
//    .image-container {
//        position: relative;
//        background-color: #212121;
//        margin-bottom: 16px;
//    }
//
//    .image-label {
//        height: 40px;
//        border-radius: 8px;
//        backdrop-filter: blur(4px);
//        background-color: rgba(0, 0, 0, 0.45);
//        position: absolute;
//        bottom: 16%;
//        left: 50%;
//        transform: translateX(-50%);
//        white-space: nowrap;
//        display: flex;
//        align-items: center;
//        padding: 0 22px;
//
//        p {
//            font-size: 16px;
//            line-height: 1.25;
//            color: rgba(255, 255, 255, 0.8);
//            white-space: nowrap;
//            margin: 0;
//        }
//    }
//
//    .close-button {
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        position: absolute;
//        top: 8px;
//        right: 16px;
//        width: 32px;
//        height: 32px;
//        border-radius: 50%;
//        background-color: rgba(0, 0, 0, .6);
//
//        svg {
//            width: 12px;
//            height: 12px;
//        }
//    }
//
//    .deeplink-button {
//        font-size: 13px;
//        font-weight: bold;
//        line-height: 1.38;
//        letter-spacing: 1.3px;
//        text-transform: uppercase;
//        color: #000;
//        width: 240px;
//        height: 40px;
//        border-radius: 40px;
//        box-shadow: 0 0 8px 0 #ffcf05;
//        background-image: linear-gradient(to right, #ffcf05 35%, #ee8834 100%);
//        margin: 0 auto;
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        margin-bottom: 8px;
//    }
//
//    p {
//        opacity: 0.75;
//        font-size: 10px;
//        line-height: 1.6;
//        color: #fff;
//        text-align: center;
//        margin-bottom: 16px;
//    }
//
//    .usual-download-button {
//        font-size: 13px;
//        font-weight: bold;
//        line-height: 1.38;
//        letter-spacing: 1.3px;
//        text-transform: uppercase;
//        color: #fff;
//        padding-top: 5px;
//        padding-bottom: 5px;
//        margin: 0 auto;
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        background: none;
//        position: relative;
//
//        &::after {
//            content: '';
//            height: 1px;
//            width: 100%;
//            background-color: #fff;
//            position: absolute;
//            bottom: 5px;
//            left: 0;
//        }
//    }
//}

@media all and (max-width: 900px) {
    .main-page {
        padding: 28px 0 24px;
    }

    .logo {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
    }

    .main-title {
        font-size: 24px;
        line-height: 1.6;
        background-color: #000;
        text-align: center;
        max-width: 100%;
        margin-bottom: 24px;
    }

    .main-subtitle {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        background-color: #000;
        max-width: 100%;
        margin-bottom: 24px;
        margin-top: 24px;
    }

    .btn-upload-foto {
        font-size: 16px;
        letter-spacing: 1.6px;
        max-width: 240px;
        height: 64px;
        margin: 0 auto 32px;
    }

    .main-arrow-icon {
        display: none;
    }

    .rules-list-container {
        font-size: 16px;
        max-width: 100%;
        margin-bottom: 32px;
    }

    .rules-list-title {
        text-align: center;
        margin-bottom: 8px;
    }

    .rules-list-item {
        margin-bottom: 8px;

        &:before {
            top: 9px;
        }
    }

    .main-info-container {
        border-radius: 0;
        width: calc(100% + 30px);
        box-sizing: border-box;
        padding: 16px 24px;
        margin-left: -15px;
        margin-bottom: 40px;
    }

    .main-info-container .info-logo {
        display: none;
    }

    .main-info-content {
        width: 100%;
        .info-image {
            height: 48px;
            margin-right: 16px;
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
        }
    }

    .main-info-content-header {
        margin-bottom: 16px;
        p {
            font-size: 16px;
            line-height: 2;
        }
    }

    .btns-container-store {
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
    }

    .btn-store {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1.6px;
        width: 240px;
        height: 48px;
        margin: 0;
        margin-bottom: 16px;
    }

    .main-video-container {
        overflow: hidden;
        position: relative;
        // z-index: -5;
        width: calc(100% + 30px);
        max-width: 450px;
        margin: 0 auto;
    }

    .choose-foto-container {
        order: -5;
    }

    .main-title {
        order: -10;
    }

    .main-video-container {
        order: -4;
    }

    .main-subtitle {
        order: -2;
        margin-top: 0;
        margin-bottom: 32px;
    }

    .btns-container-upload {
        order: -3;
        margin-top: 24px;
        margin-bottom: 24px;

        .btn-upload-foto {
            margin-bottom: 0;
        }
    }

    .main-page .btn-upload-foto {
        max-width: 290px;
    }
}

.btns-container-creative {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 440px;
    margin: 0 auto;
}

//.btns-container-refresh {
//    display: flex;
//    justify-content: flex-end;
//    flex-shrink: 0;
//    flex-grow: 1;
//}

.creative-tabs {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;
    overflow: auto;
    max-width: 84%;
    width: calc(100% + 15px);
    margin-right: -16px;
    padding-right: 16px;
    padding-bottom: 6px;

    &::-webkit-scrollbar-track {
        background-color: transparent;
        height: 0;
        border-radius: 8px;
    }

    &::-webkit-scrollbar {
        padding: 10px 0;
        height: 0;
        border-radius: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 8px;
    }

    button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        flex-shrink: 0;
        background-size: cover;
        position: relative;
        // border: solid 3px #01e777;
        background-color: #01e777;
        margin-left: 16px;

        &.active {
            border: solid 3px #01e777;
            height: 48px;
            width: 48px;
        }

        .timer-loader {
            z-index: 10;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
    }

    &.-hide {
        visibility: hidden;
    }

    &.-remove {
        display: none;
    }

    .new {
        position: relative;

        span {
            font-size: 5px;
            font-weight: bold;
            text-transform: uppercase;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            box-sizing: border-box;
            background-color: #ffcf05;
            box-shadow: 0 0 8px 0 #ffcf05;
        }
    }
}

.timer-loader {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -10;
    margin: 0;

    .rotate {
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        z-index: 10;
    }

    .rotate path {
        fill: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(50%, 50%);
    }
}

.ui--creative-tabs-v2 {
    .loader-roller-wrapper {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        margin: 0 auto 24px;
    }

    .article-related {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
        padding-top: 120px;
        margin-bottom: 120px;
    }
    
    .article-related .container {
        text-align: center;
        border: none;
    }
    
    .article-related h3 {
        font-size: 40px;
        font-weight: bold;
        line-height: 1.4;
        flex-shrink: 0;
        margin-bottom: 40px;
    }

    .slider {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 32px 16px;
    }

    .swiper-slide {
        background: none;
        flex-grow: 1;
    }
    
    .swiper-slide img {
        height: 240px;
        width: 100%;
        object-fit: cover;
        object-position: top center;
        margin-bottom: 24px;
    }
    
    .swiper-slide h4 {
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        text-align: left;
        line-height: 1.67;
        position: relative;
        padding-right: 40px;
        margin-bottom: 8px;
    }
    
    .swiper-slide h4 svg {
        width: 40px;
        position: absolute;
        right: 0;
        top: 0;
    }
    
    .swiper-slide p {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        text-align: left;
        margin-bottom: 16px;
    }
    
    .swiper-slide .slide-date p {
        font-size: 16px;
        line-height: 1.5;
        color: #cdcdcd;
        text-align: left;
        margin-bottom: 0;
    }
    
    @media all and (max-width: 991px) {
        .article-related {
            flex-direction: column;
            padding-top: 56px;
            margin-bottom: 64px;
        }
    
        .article-related .container {
            position: static;
            transform: none;
        }
    
        .article-related h3 {
            font-size: 24px;
            line-height: 1.67;
            text-align: center;
            margin-bottom: 24px;
        }

        .slider {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media all and (max-width: 480px) {
        .slider {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.loader-roller-wrapper {
    width: 80px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 20;
    margin: 0 auto;
}

.loader-roller {
    width: 80px;
    height: 50px;
    top: 0;
    left: 0;
    position: absolute;

    &:after {
        content: "";
        top: auto;
        position: absolute;
        display: block;
        animation: shadow 1.2s linear infinite;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 12px;
        border-radius: 50%;
        background-color: #000;
    }

    .roller {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 1.2s;
    
        &:first-child {
            left: 0;
            animation-name: rollercoaster;
            transform: rotate(135deg);
        }

        &:last-child {
            right: 0;
            animation-name: rollercoaster2;
            transform: rotate(-45deg);
        }

        &:before {
            content: "";
            display: block;
            width: 12px;
            height: 12px;
            background-color: #a4e6a7;
            border-radius: 50%;
        }
    }
}

.loader-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        pointer-events: none;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        backdrop-filter: blur(8px);
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 10;
    }
}

.loader-text {
    position: relative;

    p {
        font-size: 16px;
        position: static;
        transform: none;
        text-align: center;
        margin: 0 auto 24px;
    }

    svg {
        position: absolute;
        top: 30px;
        width: calc(100% + 40px);
        left: 50%;
        transform: translateX(-50%);
    }

    li {
        font-size: 14px;
        font-style: italic;
        margin-bottom: 12px;
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.05);
        max-width: 320px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1.42;
        padding: 0 24px;
        box-sizing: border-box;
    }
}

.loader-roller2 .roller,
.loader-roller2:after {
    animation-delay: .15s
}

.loader-roller3 .roller,
.loader-roller3:after {
    animation-delay: .3s
}

@keyframes rollercoaster {
    0% {
        transform: rotate(135deg)
    }

    8% {
        transform: rotate(240deg)
    }

    20% {
        transform: rotate(300deg)
    }

    40% {
        transform: rotate(380deg)
    }

    45% {
        transform: rotate(440deg)
    }

    50% {
        transform: rotate(495deg);
        opacity: 1
    }

    50.1% {
        transform: rotate(495deg);
        opacity: 0
    }

    to {
        transform: rotate(495deg);
        opacity: 0
    }
}

@keyframes rollercoaster2 {
    0% {
        opacity: 0
    }

    49.9% {
        opacity: 0
    }

    50% {
        opacity: 1;
        transform: rotate(-45deg)
    }

    58% {
        transform: rotate(-160deg)
    }

    70% {
        transform: rotate(-240deg)
    }

    80% {
        transform: rotate(-300deg)
    }

    90% {
        transform: rotate(-340deg)
    }

    to {
        transform: rotate(-405deg)
    }
}

@keyframes shadow {
    0% {
        opacity: .3;
        transform: translateX(35px) scale(.25)
    }

    8% {
        transform: translateX(9px) scale(1)
    }

    20% {
        transform: translateX(0) scale(.6)
    }

    40% {
        transform: translateX(-5px) scale(.25);
        opacity: .1
    }

    50% {
        transform: translateX(32px) scale(.6);
        opacity: .3
    }

    60% {
        transform: translateX(70px) scale(1);
        opacity: .05
    }

    65% {
        transform: translateX(78px) scale(.6)
    }

    80% {
        transform: translateX(65px) scale(.25);
        opacity: .1
    }

    90% {
        transform: translateX(43px) scale(.4)
    }

    to {
        transform: translateX(32px);
        opacity: .3
    }
}

.text-modal {
    position: absolute  ;
    width: 194px;
    bottom: 8px;
    right: -20px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 8px;

    p {
        font-size: 13px;
        color: #fff;
        line-height: 1.38;
        position: static;
        transform: none;
        text-align: left;
        letter-spacing: 0.5px;

        &:first-child {
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .btn-close-tooltip {
        position: absolute;
        top: 2px;
        right: 2px;
        padding: 4px;
        width: 16px;
        height: 16px;
        background: none;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
        }
    }
}

//.btn-choose-text {
//    font-weight: 700;
//    font-size: 12px;
//    color: #01e777;
//    text-transform: uppercase;
//    line-height: 1;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    position: absolute;
//    top: -16px;
//    right: -10px;
//    background: none;
//    padding: 0 10px 10px;
//
//    img {
//        width: 12px;
//        margin-right: 6px;
//    }
//}

.error-container {
    position: absolute;
}

.creative-error-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        position: static;
        transform: none;
    }
}

.webview {
    .logo,
    .rules-list-container,
    .main-info-container,
    .btns-container-store,
    .footer-links {
        display: none;
    }

    .btn-upload-foto {
        margin-bottom: 0;
    }

    // .main-page {
    //     min-height: auto;
    // }
    .full-size-buttons-class {
        .btn-upload-foto {
            font-size: 16px;
            height: 48px;
        }

        .btns-container-upload {
            margin-top: 16px;
            margin-bottom: 16px;
        }

        &.result-page {
            justify-content: flex-start;
            padding-top: 16px;

            .btn-result {
                order: -10;
            }
        }
    }

    &.ui--creative-tabs-v2  {
        // .full-size-buttons-class {
        //     @media all and (max-height: 720px) and (orientation: portrait) {
        //         .creative-block {
        //             max-height: 48vh;
        //         }
        //     }

        //     @media all and (max-height: 600px) and (orientation: portrait) {
        //         .creative-block {
        //             max-height: 40vh;
        //         }
        //     }

        //     @media all and (max-height: 520px) and (orientation: portrait) {
        //         .creative-block {
        //             max-height: 36vh;
        //         }
        //     }
        // }
    }

    .main-page .container {
        border-bottom: none;
    }
}

.creative .watermark-image {
    position: absolute;
    right: 0;
    bottom: 0;
    //width: 153px;
    //height: auto;
    border-radius: 0;
    pointer-events: none;
}

.watermark-container {
    position: absolute;

    .tooltip {
        font-size: 12px;
        height: 34px;
        backdrop-filter: blur(8px);
        background-color: #01e777;
        color: #000;
        min-width: 130px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 0 8px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 14px;
            bottom: -8px;
            width: 0;
            height: 0;
            border-width: 8px 3px 0 3px;
            border-color: #01e777 transparent transparent transparent;
            border-style: solid;
        }

        p {
            font-size: inherit;
            position: static;
            transform: none;
            color: #000;
        }
    }

    .btn-remove-logo {
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        height: 100%;
        min-width: 160px;
        height: 34px;
        right: 0;
    }
}

.resubscribe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40px;
    min-height: 90vh;

    .loader {
        width: 124px;
        height: 124px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 16px;
    }

    .loader-text-container {
        display: none;
    }

    .spinner-container {
        position: relative;
        transform: none;
        top: auto;
        left: auto;
        display: flex;
        justify-content: center;

        svg {
            width: 60px;
        }
    }

    .avatar-image {
        width: 60px;
        height: 60px;
    }

    .triple-spinner {
        width: 100px;
        height: 100px;
        border-width: 2px;

        &::before {
            border-width: 2px;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
        }
    }

    .triple-spinner2 {
        width: 120px;
        height: 120px;
        border-width: 2px;

        &::before {
            border-width: 2px;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
        }
    }

    .loader-text {
        opacity: 0.45;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #fff;
        margin-bottom: 20px;
    }

    button {
        font-size: 13px;
        font-weight: bold;
        line-height: 1.38;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: #000;
        height: 40px;
        padding: 0 30px;
        border-radius: 24px;
        background-color: #01e777;
    }
}

.resubscribe-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 5px;
}

.resubscribe-image {
    width: 100%;
    max-width: 460px;
    margin: 0 auto 16px;

    @media all and (max-height: 820px) and (orientation: portrait) {
        max-width: 340px;
    }

    @media all and (max-height: 760px) and (orientation: portrait) {
        max-width: 270px;
    }

    @media all and (max-height: 650px) and (orientation: portrait) {
        max-width: 220px;
    }

    @media all and (max-height: 590px) and (orientation: portrait) {
        max-width: 180px;
    }

    @media all and (max-height: 550px) and (orientation: portrait) {
        max-width: 140px;
    }

    @media all and (max-height: 500px) and (orientation: portrait) {
        max-width: 120px;
    }
}

.footer-links {
    // position: absolute;
    // bottom: 24px;
    // left: 50%;
    // transform: translateX(-50%);
    width: 100%;
    margin: 0;
  
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      border: none;
    }
  
    a {
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      line-height: 1.5;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      margin: 0 12px;
  
      @media all and (max-width: 460px) {
        font-size: 12px;
        margin: 0 8px;
      }
  
      &:hover {
        opacity: .6;
      }
    }
  
}

.btn-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    background: none;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, .2);

    svg {
        width: 32px;
        fill: #fff;
        position: relative;
        z-index: 10;
    }
}

.main-header {
    display: flex;
    align-items: center;
    position: relative;
    align-self: flex-start;
    margin-bottom: 40px;
}

.lang-change {
    position: absolute;
    top: 3px;
    right: -240px;
    border-radius: 24px;
    border: solid 1px #cbcbcb;
    background-color: #000;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 20;

    ul {
        position: relative;
        z-index: 10;
        background-color: #000;
        padding-bottom: 12px;
        padding-top: 16px;

        button {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 12px;
        }

        .lang-change-flag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            margin-right: 12px;
        }

        .lang-change-name {
            text-align: left;
            opacity: 0.3;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
        }
    }

    li {
        margin-top: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: -9px;
        }
    }

    button {
        background-color: #000;
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: #000;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }

    &-open {
        background-color: #222;

        &::after,
        ul,
        button {
            background-color: #222;
        }

        .lang-change-icon {
            transform: rotate(180deg);
        }
    }
}

.lang-change-main-btn {
    width: 180px;
    height: 48px;
    padding: 8px;
    // border-radius: 48px;
    box-sizing: border-box;
    // box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    // border: solid 1px #cbcbcb;
    background-color: #000;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
}

.lang-change-flag {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 8px;

    img {
        width: 100%;
        height: 100%;
    }
}

.lang-change-name {
    font-size: 16px;
    color: #fff;
    flex-grow: 1;
    line-height: 1;
    text-align: left;
    margin-right: 12px;
}

.lang-change-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: #fff;
}

@media all and (max-width: 900px) {
    .lang-change {
        border-radius: 48px;
        top: -1px;

        &-open {
            border-radius: 24px;
        }

        ul {
            padding-top: 0;

            button {
                padding: 0 16px;
            }

            .lang-change-flag {
                margin-right: 8px;
            }
        }

        li {
            &:first-child {
                margin-top: 9px;
            }
        }
    }

    .lang-change-main-btn {
        width: 150px;
        height: 40px;
        padding: 0 16px;
    }

    .lang-change-flag {
        width: 24px;
        height: 24px;
    }

    .lang-change-name {
        font-size: 16px;
        line-height: 1.5;
        margin-right: 12px;
    }

    .lang-change-icon {
        width: 16px;
        height: 16px;
    }
}

@media all and (max-width: 900px) {
    .main-header {
        position: static;
        order: -15;
        margin-bottom: 18px;
    }

    .lang-change {
        top: 20px;
        right: 16px;
    }
}

@media all and (max-height: 690px) and (orientation: portrait) {
    .resubscribe {
        padding-top: 16px;
    }

    .resubscribe .loader {
        width: 108px;
        height: 108px;
        margin-bottom: 8px;
    }

    .resubscribe .spinner-container svg {
        width: 52px;
    }

    .resubscribe .avatar-image {
        width: 52px;
        height: 52px;
    }

    .resubscribe .triple-spinner {
        width: 86px;
        height: 86px;
    }

    .resubscribe .triple-spinner2 {
        width: 104px;
        height: 104px;
    }
}

.resubscribe-text1 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #01e777;
}

.resubscribe-text2 {
    font-size: 16px;
    line-height: 1.5;
    color: #01e777;
    margin-bottom: 8px;
}

.resubscribe-text3 {
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    margin-bottom: 16px;
}

.btn-result-gold {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 30px);
    height: 56px;
    flex-shrink: 0;
    background-image: linear-gradient(86deg, #e1e401 1%, #ee8834 73%);
    order: -15;
    margin-top: -28px;
    margin-bottom: 11px;
    margin-left: -15px;

    p {
        display: flex;
        align-items: center;
        position: relative;
    }

    span {
        span {
            text-transform: uppercase;
            text-decoration: underline;
        }
    }

    svg {
        width: 16px;
        margin-left: 8px;
    }

    .btn-result-label {
        position: absolute;
        right: -35px;
        top: -10px;
    }

    @media all and (max-width: 390px) {
        font-size: 14px;
    }
}

.btn-result {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 290px;
    height: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 32px;
    border: solid 1px #fff;
    background: none;
    order: -2;
    margin: 0 auto 16px;

    .btn-result-label {
        color: #000;
        position: absolute;
        top: -8px;
        right: -4px;
        box-shadow: 0 0 8px 0 #ffcf05;
    }
}

.btn-result-label {
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
    width: 32px;
    height: 16px;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(to right, #ffcf05 35%, #ee8834 100%);
}

.neuro-image-heder-container {
    height: 48px;
    display: flex;
    justify-content: center;
    order: -3;
    margin-top: 6px;
    margin-bottom: 16px;
}

.neuro-image-heder {
    height: 100%;
}

.neuro-container {
    position: relative;
    background-color: #005250;
    padding-top: 104px;
    padding-bottom: 96px;
    margin-bottom: 40px;

    .neuro-bg-container {
        height: 240px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
       
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            height: 340px;
            width: 120px;
            background-image: linear-gradient(to right, rgba(0, 82, 80, 0) 0, rgba(0, 82, 80, 1) 100%);
            z-index: 10;
        }
    }

    .neuro-bg {
        height: 100%;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border: none;
        position: relative;
    }

    h2 {
        position: relative;
        margin-bottom: 24px;
    }

    .neuro-title {
        font-size: 48px;
        font-weight: bold;
        line-height: 1.33;
        color: #fff;
        margin-bottom: 24px;
    
        span {
            color: #00ede8;
        }
    }

    .neuro-subtitle {
        transform: rotate(-4deg);
        font-family: "FuzzyBubbles", cursive;
        font-size: 24px;
        line-height: 1.33;
        color: #ff8d00;
        position: absolute;
        left: -120px;
        top: -30px;
        text-align: center;
        span {
            text-decoration: underline;
        }
    }

    p {
        font-size: 24px;
        line-height: 1.67;
        text-align: right;
        color: #fff;
        margin-bottom: 32px;
    }

    .neuro-image-mob {
        display: none;
    }

    button {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1.2px;
        color: #000;
        width: 320px;
        height: 80px;
        border-radius: 40px;
        background-color: #00ede8;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    @media all and (max-width: 900px) {
        overflow: hidden;
        padding-top: 16px;
        padding-bottom: 24px;
        margin-bottom: 24px;

        .neuro-bg-container {
            display: none;
        }

        .container {
            align-items: center;
        }

        .neuro-title {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 4px;
        }

        h2 {
            margin-bottom: 2px;
        }

        p {
            font-size: 12px;
            line-height: 1.67;
            text-align: center;
            margin-bottom: 0;

            br {
                display: none;
            }
        }

        .neuro-image-mob {
            display: block;
            width: calc(100% + 140px);
            max-width: 415px;
            margin-bottom: 0;
        }

        .neuro-subtitle {
            transform: rotate(-356deg);
            font-size: 12px;
            line-height: 1.33;
            color: #ffd006;
            left: auto;
            right: -60px;
            top: -12px;
        
        }

        button {
            font-size: 12px;
            letter-spacing: 0.6px;
            width: 200px;
            height: 36px;
        }
    }
}

.app-lang--ru {
    .main-title {
        font-size: 64px;

        @media all and (max-width: 900px) {
            font-size: 24px;
        }
    }

    .main-subtitle {
        font-size: 36px;

        @media all and (max-width: 900px) {
            font-size: 16px;
        }
    }

    .mask_editor_screen .toolbox button span {
        width: auto;
    }

    .mask_editor_screen .toolbox .buttons-container-tools .tool-save {
        width: auto;
        padding: 0 10px;
    }

    @media all and (max-height: 780px) and (orientation: landscape) {
        .main-title {
            font-size: 52px;
        }

        .main-subtitle {
            font-size: 32px;
        }
    }

    @media (max-width: 390px) {
        .btn-result-gold p {
            margin-left: -30px;
        }
    }
}

.app-lang--es,
.app-lang--pt,
.app-lang--it {
    @media (max-width: 490px) {
        .btn-result-gold {
            font-size: 12px;
        }
    }

    @media (max-width: 390px) {
        .btn-result-gold p {
            margin-left: -30px;
        }
    }

    @media (max-width: 350px) {
        .btn-result-gold {
            font-size: 11px;

            svg {
                width: 12px;
                margin-left: 6px;
            }
        }
    }
}

.app-lang--de {
    @media all and (max-height: 1050px) and (orientation: landscape) {
        .main-title {
            font-size: 50px;
        }
    }
}

.app-lang--es {
    @media all and (max-height: 1100px) and (orientation: landscape) {
        .main-title {
            font-size: 60px;
        }
    }

    @media all and (max-height: 890px) and (orientation: landscape) {
        .main-subtitle {
            font-size: 36px;
            margin-bottom: 24px;
        }
    }

    @media all and (max-height: 750px) and (orientation: landscape) {
        .main-title {
            font-size: 50px;
        }

        .main-subtitle {
            font-size: 30px;
        }
    }
}

.app-lang--fr {
    @media all and (max-height: 1180px) and (orientation: landscape) {
        .main-title {
            font-size: 60px;
        }
    }

    @media all and (max-height: 960px) and (orientation: landscape) {
        .main-title {
            font-size: 50px;
        }
    }

    @media all and (max-height: 900px) and (orientation: landscape) {
        .main-title {
            font-size: 42px;
        }
    }
}

.app-lang--it {
    @media all and (max-height: 1200px) and (orientation: landscape) {
        .main-title {
            font-size: 60px;
        }
    }

    @media all and (max-height: 960px) and (orientation: landscape) {
        .main-title {
            font-size: 50px;
        }
    }

    @media all and (max-height: 900px) and (orientation: landscape) {
        .main-title {
            font-size: 42px;
        }
    }
}

.app-lang--pt {
    @media all and (max-height: 950px) and (orientation: landscape) {
        .main-title {
            font-size: 54px;
        }
    }
}


@media all and (max-height: 1050px) and (orientation: landscape) {
    .tab-container {
        max-width: 550px;
    }
    .creative-holder,
    .btns-container-creative,
    .creative-container {
        max-width: 514px;
    }
}

@media all and (max-height: 960px) and (orientation: landscape) {
    .main-title {
        font-size: 70px;
    }
}

@media all and (max-height: 870px) and (orientation: landscape) {
    .result-page {
        h2 {
            font-size: 24px;
            margin-bottom: 16px;
        }

        .btn-back {
            width: 24px;
            left: 16px;
            top: 26px;
        }
    }

    .editor-page  {
        padding-top: 16px;
        padding-bottom: 16px;
        h2 {
            font-size: 24px;
            margin-bottom: 0;
            margin-right: 40px;
        }

        .btn-back {
            width: 24px;
            left: 16px;
            top: 16px;
        }

        p {
            margin-bottom: 0;

            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }
    }

    .editor-page-container {
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-bottom: 16px;
    }

    .mask_editor_screen {
        .canvas_holder {
            margin-bottom: 24px;
        }
        .controlbox {
            margin-bottom: 34px;

            .control_name, 
            .control_value {
                font-size: 20px;
                top: 12px;
            }
        }
        .toolbox {
            margin-bottom: 22px;
            button {
                margin-right: 32px;
                svg {
                    height: 32px;
                }
            }
        }

        .tool-save {
            font-size: 18px;
            height: 48px;
        }
    }

    .app-lang--ru {
        .mask_editor_screen .toolbox .tool {
            margin-right: 48px;

            &:last-child {
                margin-right: 0;
            }
        }

        .mask_editor_screen .toolbox button span {
            bottom: -20px;
            font-size: 14px;
        }
    }
}

@media all and (max-height: 850px) and (orientation: landscape) {
    .main-title {
        font-size: 70px;
        margin-bottom: 24px;
    }

    .main-subtitle {
        font-size: 36px;
        margin-bottom: 24px;
    }

    .main-video-container {
        width: 480px;
    }

    .mask-container .tooltip {
        top: 12px;
        right: 58px;

        &:after {
            right: -6px;
            bottom: 9px;
            border-width: 5px 0px 5px 6px;
            border-color: transparent transparent transparent rgba(1, 231, 119, 0.8);
        }
    }
}

@media all and (max-height: 800px) and (orientation: landscape) {
    .main-title {
        font-size: 58px;
    }

    .main-subtitle {
        font-size: 30px;
    }
}

@media all and (max-height: 730px) and (orientation: landscape) {
    .loader-roller-wrapper {
        margin-bottom: 4px;
    }

    .loader-text svg {
        width: 77%;
    }

    .loader-text p {
        margin-bottom: 12px;
    }

    .loader-text li {
        font-size: 12px;
        max-width: 380px;
        height: 40px;
        margin-bottom: 8px;
    }
}

@media all and (max-height: 680px) and (orientation: landscape) {
    .btn-get {
        font-size: 13px;
        letter-spacing: 1.3px;
        height: 40px;
    }

    .main-title {
        font-size: 54px;
        margin-bottom: 20px;
    }

    .main-subtitle {
        font-size: 28px;
        margin-bottom: 30px;
    }
}

@media all and (max-height: 640px) and (orientation: landscape) {
    .main-title {
        font-size: 54px;
        margin-bottom: 20px;
    }

    .main-subtitle {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .tab-container {
        margin-bottom: 16px;
    }

    .creative-container {
        margin-bottom: 16px;
    }

    .result-page {
        padding-top: 24px;
    }
}

@media all and (max-height: 600px) and (orientation: landscape) {
    .ui--creative-tabs-v2 .creative-block {
        max-height: 46vh;
    }
}

@media all and (max-width: 780px) {
    .app-lang--ru {
        .error-container button {
            padding: 0 20px;
        }
    }

    .btns-container-creative {
        box-sizing: border-box;
        padding: 0 15px;
    }

    .creative-download {
        font-size: 16px;
        width: 240px;
        height: 48px;
        margin-bottom: 16px;
    }

    .btns-container-creative {
        .creative-download {
            font-size: 13px;
            letter-spacing: 1.3px;
            height: 40px;
            margin: 0;
        }
    }

    .btn-upload {
        font-size: 13px;
        letter-spacing: 1.3px;
        height: 40px;
    }

    .btn-back {
        left: 60px;
    }

    .editor-page  {
        p {
            display: none;
        }
    }

    .btn-choose-text {
        font-size: 8px;
        img {
            width: 8px;
            margin-right: 4px;
        }
    }

    .error-page .btn-upload-foto {
        height: 40px;
        letter-spacing: 1.3px;
        font-size: 13px;
        padding: 0 12px;

        .effects {
            span {
                padding-left: 12px;
            }
        }
    }

    .error-content {
        position: relative;
        padding-top: 248px;

        img {
            position: absolute;
            top: 0;
            left: -30px;
        }
    }
}

@media all and (max-width: 720px) {
    .creative-container {
        box-sizing: border-box;
        padding: 0 16px;
    }
}

@media all and (max-width: 560px) {
    .creative-holder {
        // flex-grow: 0;

        // &:after {
        //     padding-top: 100%;
        // }
    }

    .btn-change-mask {
        box-sizing: border-box;
        padding: 4px;
    }

    .creative-tabs button {
        width: 32px;
        height: 32px;

        &.active {
            width: 46px;
            height: 46px;   
        }
    }

    .main-page {

        .container {
            height: 100%;
        }

        .main-video-container {
            flex-grow: 1;
        }
    }
    
    .main-info-content-header {
        flex-direction: column;
    }
    .result-page {
        // height: 92vh;
        padding-top: 32px;
        padding-bottom: 16px;

        h2 {
            font-size: 24px;
            line-height: 1;
        }
    }

    .creative-download {
        font-size: 16px;
        height: 48px;
    }

    .ios {
        .editor-page,
        .result-page {
            // padding-bottom: 0;
            // height: 82vh;
            height: $window-inner-height;
        }

        &.webview {
            .main-page {
                // height: 88vh;
                // height: $window-inner-height;
                padding-bottom: 16px;
            }

            .editor-page {
                padding-bottom: 0;
                // height: 92vh;
                height: $window-inner-height;
            }

            .result-page {
                // height: 92vh;
                height: $window-inner-height;
                padding-bottom: 16px; 
            }
        }
    }

    .webview {
        .main-page {
            // height: 94vh;
            // height: $window-inner-height;
        }
        .editor-page {
            // height: 98vh;
            height: $window-inner-height;
            padding-bottom: 0;
        }

        .result-page {
            // height: 96vh;
            height: $window-inner-height;
        }
    }

    .editor-page  {
        padding-top: 24px;
        padding-bottom: 24px;

        h2 {
            font-size: 24px;
            line-height: 1;
        }
    }

    .btn-back {
        width: 24px;
        left: 16px;
        top: 24px;

    }

    .loader-text p {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .loader-text li {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 8px;
    }

    .mask-container .tooltip {
        top: 12px;
        right: 58px;

        &:after {
            right: -6px;
            bottom: 9px;
            border-width: 5px 0px 5px 6px;
            border-color: transparent transparent transparent rgba(1, 231, 119, 0.8);
        }
    }
}

@media all and (max-height: 830px) and (orientation: portrait) {
    .result-page {
        padding-top: 32px;
        h2 {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 16px;
        }
    }
    .editor-page  {
        h2 {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 32px;
        }
    }

    .btn-choice-tab {
        width: 56px;
        height: 56px;
        border-radius: 16px;
    }

    .timer-loader {
        width: 40px;
        height: 40px;
    }
}

@media all and (max-width: 480px) {
    .main-title {
        font-size: 19px;
        width: calc(100% + 30px);
        max-width: initial;
        background-color: #000;
        box-sizing: border-box;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 24px;
        margin: 0;
        margin-left: -15px;
    }

    h1.main-title {
        padding-bottom: 0;
    }

    h2.main-title {
        font-size: 17px;
    }

    .app-lang--ru .main-title {
        font-size: 17px;
    }

    .app-lang--ru h2.main-title {
        font-size: 15px;
    }

    .main-subtitle {
        // width: calc(100% + 30px);
        max-width: initial;
        // background-color: #000;
        box-sizing: border-box;
        // padding-left: 15px;
        // padding-right: 15px;
        // padding-top: 24px;
        // margin: -50px -15px 24px;
    }

    .main-video-container {
        max-width: 320px;
        width: 100%;
        margin: 0px auto;
    }

    .text-modal {
        right: 16px;
    }

    .webview {
        .main-video-container {
            max-width: 400px;
        }
    }
}

@media all and (max-width: 440px) {
    .btn-choose-text {
        right: 0;
    }

    .btns-container-refresh {
        padding-right: 0;
        box-sizing: border-box;
    }

    .btn-get {
        font-size: 13px;
        letter-spacing: 1.3px;
        height: 40px;
        padding: 0 30px;
    }

    .loader-text svg {
        top: 28px;
        width: 100%;
    }

    //.safe-and-cool svg {
    //    width: 16px;
    //    margin-left: 8px;
    //}
}

@media all and (max-width: 410px) {
    .loader-text p {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .loader-text li {
        font-size: 12px;
        margin-bottom: 6px;
    }
}

@media all and (max-width: 390px) {
    .app-lang--ru {
        .mask_editor_screen .toolbox button {
            margin-right: 20px;
        }

        //.safe-and-cool a {
        //    font-size: 12px;
        //}
    }

    .result-page .creative-holder {
        padding: 0 10px;
    }
}

@media all and (max-width: 360px) {
    .loader-text p {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .loader-text li {
        font-size: 11px;
        margin-bottom: 6px;
    }

    //.safe-and-cool a {
    //    font-size: 14px;
    //}
}

@media all and (max-height: 930px) and (orientation: portrait) {
    .webview {
        .main-video-container {
            max-width: 400px;
        }
    }
}

@media all and (max-height: 860px) and (orientation: portrait) {
    .webview {
        .main-video-container {
            max-width: 360px;
        }
    }
}

@media all and (max-height: 800px) and (orientation: portrait) {
    .editor-page  {
        h2 {
            margin-bottom: 16px;
        }
    }

    .webview {
        .main-video-container {
            max-width: 340px;
        }
    }
}

@media all and (max-height: 780px) and (orientation: portrait) {
    .main-title {
        line-height: 1.5;
        padding-bottom: 10px;
    }

    .main-video-container {
        max-width: 280px;
        width: 100%;
        margin: 0px auto;
    }

    .main-subtitle {
        padding: 0 15px 15px;
        margin: 0px -15px;
    }

    .webview .main-subtitle {
        padding-bottom: 0;
    }
}

@media all and (max-height: 740px) and (orientation: portrait) {
    .webview {
        .main-video-container {
            max-width: 300px;
        }
    }
}

@media all and (max-height: 700px) and (orientation: portrait) {
    .main-video-container {
        max-width: 260px;
    }
}

@media all and (max-height: 680px) and (orientation: portrait) {
    .main-video-container {
        max-width: 220px;
    }

    .webview {
        .main-video-container {
            max-width: 250px;
        }
    }
}

@media all and (max-height: 630px) and (orientation: portrait) {
    .result-page .creative-holder {
        padding-top: 16px;
    }

    .loader-roller-wrapper {
        margin-bottom: 8px;
    }

    .loader-text li {
        height: 40px;
    }

    .loader-text svg {
        top: 24px;
        width: 85%;
    }

    .app-lang--ru .main-title,
    .main-title {
        font-size: 18px;
    }
}

// @media all and (max-height: 600px) and (orientation: portrait) {
//     .ui--creative-tabs-v2 .creative-block {
//         max-height: 54vh;
//     }
// }

@media all and (max-height: 540px) and (orientation: portrait) {
    .slide {
        width: 160px;
        height: 160px;
    }

    .error-content {
        padding-top: 208px;
    }

    .error-content img {
        height: 160px;
        margin-bottom: 24px;
    }

    .error-page .btn-upload-foto {
        margin-top: 16px;
    }
}

@media all and (max-height: 770px) and (max-width: 375px) and (orientation: portrait) {
    .webview .main-video-container {
        max-width: 310px;
    }
}

@media all and (max-height: 740px) and (max-width: 375px) and (orientation: portrait) {
    .webview .main-video-container {
        max-width: 290px;
    }
}

@media all and (max-height: 700px) and (max-width: 375px) and (orientation: portrait) {
    .webview .main-video-container {
        max-width: 260px;
    }
}

@media all and (max-height: 670px) and (max-width: 375px) and (orientation: portrait) {
    .webview .main-video-container {
        max-width: 220px;
    }
}

.main-page--neuroavatars-shown {
    .main-subtitle {
        order: -3;
    }

    .btns-container-upload {
        margin-top: 0;
    }

    .btns-container-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;

        button {
            font-size: 12px;
            letter-spacing: 0.6px;
            max-width: 160px;
            width: 100%;
            height: 36px;
            margin: 0 4px;
        }
    }
}

.ui--neuroavatars-promo.webview  {
    .main-header {
        margin: 0;
    }

    .main-title {
        font-size: 18px;
        line-height: 1.6;
        margin: 0;
        width: 100%;
        padding: 0;
    }

    .neuro-image-heder-container {
        height: 88px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .main-subtitle {
        padding: 0;
        margin: 0;
    }

    .btns-container-upload {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    .btns-container-upload button {
        box-sizing: border-box;
        max-width: inherit;
        padding: 0 6px;
    }

    .full-size-buttons-class .btn-upload-foto {
        font-size: 12px;
        letter-spacing: 0.24px;
        height: 36px;
        width: 36%;
    }

    .btn-result {
        font-weight: bold;
        text-transform: uppercase;
        width: 62%;
    }

    .neuro-container {
        flex-grow: 1;
        background-color: #111;
        padding-top: 24px;
        margin-bottom: 0;
    }

    .main-page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        padding-bottom: 0;
    }

    .neuro-container .neuro-title {
        font-size: 20px;
        line-height: 1.2;
        color: #fff;
        margin-bottom: 8px;
    }

    .neuro-container p {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
    }

    .neuro-container button {
        width: 240px;
        height: 48px;
        border-radius: 32px;
        background-color: #ffcf05;
        font-size: 16px;
        letter-spacing: 0.8px;
        color: #000;
    }

    .neuro-container .neuro-image-mob {
        max-width: 380px;
    }

    .neuro-container .neuro-title span {
        color: #ffcf05;
    }
}

@media all and (max-height: 720px) and (orientation: portrait) {
    .ui--neuroavatars-promo.webview .main-page {
        padding-top: 16px;
    }

    .ui--neuroavatars-promo.webview .main-title {
        font-size: 16px;
        line-height: 1.5;
    }

    .ui--neuroavatars-promo.webview .neuro-image-heder-container {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .ui--neuroavatars-promo.webview .main-subtitle {
        font-size: 14px;
        line-height: 1.43;
    }

    .ui--neuroavatars-promo.webview .main-page .container {
        flex-grow: 1;
        justify-content: center;


        &:last-child {
            flex: none;
        }
    }
}

@media all and (max-height: 670px) and (orientation: portrait) {
    .ui--neuroavatars-promo.webview .neuro-image-heder-container {
        height: 64px;
    }

    .ui--neuroavatars-promo.webview .btns-container-upload {
        margin-top: 16px;
        margin-bottom: 24px;
    }

    .ui--neuroavatars-promo.webview .neuro-container {
        padding-top: 16px;
    }

    .ui--neuroavatars-promo.webview .neuro-container button {
        width: 200px;
        height: 40px;
        font-size: 14px;
        letter-spacing: 0.28px;
    }

    .ui--neuroavatars-promo.webview .neuro-container .neuro-title {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 4px;
    }

    .ui--neuroavatars-promo.webview .neuro-container p {
        font-size: 14px;
        line-height: 1.43;
    }
}

@media all and (max-height: 600px) and (orientation: portrait) {
    .ui--neuroavatars-promo.webview .neuro-container {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .ui--neuroavatars-promo.webview .neuro-image-heder-container {
        height: 56px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .ui--neuroavatars-promo.webview .btns-container-upload {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .ui--neuroavatars-promo.webview .neuro-container .neuro-image-mob {
        max-width: 340px;
    }
}

@media all and (max-height: 560px) and (orientation: portrait) {
    .ui--neuroavatars-promo.webview .neuro-image-heder-container {
        height: 48px;
    }
}

